import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { useInit } from "../utils/useInit";
import { useInputV4 } from "../utils/useInputV4";
import InputV4 from "../utils/input_V4";
import Button from "../components/button";
import { AppContext } from "../context/appContext";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "./validators";

const LoginForm = ({ login }) => {
  const { iid } = useParams();

  const navigate = useNavigate();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const { inputState, inputHandler, attachmentHandler } = useInputV4({});

  const appData = useContext(AppContext);

  const submitHandler = () => {
    initStarted(0);
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/api/auth/login/admin`,
        {
          email: inputState.inputs.email.value,
          password: inputState.inputs.password.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },

          withCredentials: true,
        }
      )
      .then((response) => {
        appData.setAuth(true);
        initStarted(1);
      })
      .catch((err) => {
        const errorMessage = err.message ? err.message : err.response.data.message;
        return initErrors(errorMessage, 0);
      });
  };

  return (
    <form
      className="mt-4 w-full"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();
        submitHandler();
      }}
    >
      {/* <input type="hidden" name="remember" defaultValue="true" /> */}
      <div className="-space-y-px rounded-md shadow-sm">
        <div className="pb-2 text-2xl">Log Into Your Account</div>

        <div>
          <InputV4
            inputVer="1"
            sid="inputs"
            type="email"
            id="email"
            placeholder={`Email`}
            customClasses={" rounded-md"}
            onInput={inputHandler}
            validators={[VALIDATOR_EMAIL()]}
            validityMessage={"Must be an email"}
          />
        </div>
        <div>
          <InputV4
            inputVer="1"
            type="password"
            sid="inputs"
            id="password"
            placeholder={`Password`}
            customClasses={"rounded-md my-1"}
            onInput={inputHandler}
            validators={[VALIDATOR_MINLENGTH(6)]}
            validityMessage={"Must be at least 6 charactors"}
          />
        </div>
      </div>

      {/* <div className="flex items-center justify-between">
        <div onClick={() => navigate("/forgotpassword")} className="text-xs cursor-pointer underline hover:text-taskinatorBlue ">
          Forgot your password?
        </div>
      </div> */}

      <div>
        <Button
          type="Submit"
          label={"Login"}
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
          disabled={!inputState.isValid}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      </div>
    </form>
  );
};

export default LoginForm;
