import React, { useEffect, useState } from "react";
import { useInit } from "../utils/useInit";
import axios from "axios";
import IconSpinner from "./iconSpinner";
import DateDisplay from "../utils/dateDisplay";

const OrganisationDisplay = ({ org }) => {
  const [orgStats, setOrgStats] = useState();
  const { initState, initStarted, initCompleted, initErrors } = useInit();

  useEffect(() => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/admin/init/orgstats/${org._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setOrgStats(response.data);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err.message ? err.message : err.response.data.message, 0);
      });
  }, [org._id]);

  return (
    <div className="border-taskinatorBlue p-2 border-2 m-2 rounded">
      <div className="font-semibold text-taskinatorBlue uppercase">{org.name}</div>
      <div className="text-xs flex">
        <div className="font-medium pr-1">Created </div>
        {DateDisplay(org.createdDate)}
      </div>

      <div className="text-xs flex">
        <div className="font-medium pr-1">Name: </div>
        {org?.ownerId?.firstName} {org?.ownerId?.lastName}
      </div>
      <div className="text-xs flex">
        {" "}
        <div className="font-medium pr-1">Email: </div>
        {org?.ownerId?.email}
      </div>
      <div className="text-xs flex"> {/* <div className="font-medium pr-1">Number:</div> {org.contactNumber} */}</div>
      <div className="pt-2 border-t  border-taskinatorMedGrey mt-2">
        {org.isTrial ? <div className="text-taskinatorRed text-sm">{`Trial - ${org.trialLimit} Job Limit`}</div> : <div className="text-taskinatorGreen text-sm">Active</div>}
      </div>

      <div className="flex text-sm pt-1">
        <div className="text-taskinatorDarkGrey font-medium pr-1">Billing Rate: </div>
        {org.billing ? <div>{org.billing.rate}</div> : <div>Not Set</div>}
      </div>
      <div className="flex text-sm">
        <div className="text-taskinatorDarkGrey font-medium pr-1">Billing Currency: </div>
        {org.billing ? <div>{org.billing.currancy}</div> : <div>Not Set</div>}
      </div>

      {initState[0].started && <IconSpinner />}
      {initState[0].error && <div className="text-sm text-taskinatorRed">Error Getting Stats</div>}
      {orgStats && (
        <div className="pt-2 text-sm border-t border-taskinatorMedGrey mt-2">
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Jobs Running:</div>
            <div>
              {orgStats.jobsRunning} | {orgStats.activeJobs}
            </div>
          </div>
          <div className="flex ">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Jobs Closed:</div>
            <div>{orgStats.jobsClosed}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Jobs Completed:</div>
            <div>
              {orgStats.jobsCompleted} | {orgStats.completedJobs}
            </div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Active Tasks</div>
            <div>{orgStats.activeTasks}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Completed Tasks</div>
            <div>{orgStats.completedTasks}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Users:</div>
            <div>{orgStats.team}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Items:</div>
            <div>{orgStats.items}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Files Sets</div>
            <div>{orgStats.fileSets}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Files</div>
            <div>{orgStats.files}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Items:</div>
            <div>{orgStats.items}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Active Workflows:</div>
            <div>{orgStats.workflows}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Active Actions:</div>
            <div>{orgStats.actions}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganisationDisplay;
