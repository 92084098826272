import React from "react";

import HeaderTitle from "./headerTitle"

const LoginScreen = (props) => {
  return (
    <div className="absolute flex items-center h-screen w-full inset-0 opacity-100 bg-taskinatorDarkGrey  overflow-hidden" >
      <div className="w-full h-fit  mx-auto lg:max-w-md  lg:shadow-lg  justify-center  items-center flex px-8 py-4  bg-taskinatorLightGrey  ">
            
        <div className=" w-full items-center flex flex-col ">
        <div className="items-center justify-center w-full my-4 flex flex-col ">
      <HeaderTitle customCSS={"text-taskinatorMedGrey justify-center flex text-4xl w-full"} largeOnly/>
     <div className="text-xs text-taskinatorMedGrey">Admin</div> 
      </div>
    
          {props.children}
       
          <div className="text-center text-xs text-taskinatorMedGrey pt-4">{`© Taskinator Pty Ltd ${new Date().getFullYear(new Date())}. All rights reserved`} </div>
        </div>
   
      </div>

    
    </div>
  );
};

export default LoginScreen;
