import React from "react";
import LoginScreen from "../components/loginScreen";
import LoginForm from "../components/loginForm"

const Login = ({ login }) => {
  return (
    <LoginScreen>
      <LoginForm login={login} />
    </LoginScreen>
  );
};

export default Login;
