import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import Dashboard from "./pages/dashboard";
import { useInit } from "./utils/useInit";
import axios from "axios";
import Login from "./pages/login";
import { AppContext } from "./context/appContext";

function App() {
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const [auth, setAuth] = useState();

  useEffect(() => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/admin/checkAuth`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setAuth(true);
        initCompleted(0);
      })
      .catch((err) => {
        initErrors(err.response.data ? err.response.data.message : err.message, 0);
      });
  }, []);

  const setViewHeight = () => {
    const newVh = window.innerHeight * 0.01;
    setVh(newVh);
  };

  useEffect(() => {
    setViewHeight();
    window.addEventListener("resize", setViewHeight);

    return () => {
      window.removeEventListener("resize", setViewHeight);
    };
  }, []);

  const vhStyle = { "--vh": `${vh}px` };

  let routes;

  if (auth) {
    routes = (
      <Routes>
        <Route path="/" element={<Dashboard />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    );
  }
  return (
    <AppContext.Provider
      value={{
        auth: auth,
        setAuth: setAuth,
      }}
    >
      <Router>
        <main style={vhStyle}>{routes}</main>
      </Router>{" "}
    </AppContext.Provider>
  );
}

export default App;
