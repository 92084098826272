import { useReducer, useCallback } from "react";

const StandardValidation = (newState) => {
  let isValid = true;

  for (const sid in newState) {
    if (sid !== "isValid") {
      for (const key in newState[sid]) {
        isValid = isValid && newState[sid][key].isValid;
      }
    }
  }
  return isValid;
};

const inputReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case "INPUT_CHANGE":
      newState = { ...state, [action.sid]: { ...state[action.sid], [action.id]: { value: action.value, isValid: action.isValid } } };
      return { ...newState, isValid: StandardValidation(newState) };

    case "REMOVE_SID":
      const currentState = state;
      delete currentState[action.sid];
      return currentState;

    case "ATTACHMENT":
      newState = { ...state, attachments: action.attachments };
      return { ...newState, isValid: StandardValidation(newState) };

    case "MUST_MATCH":
      return { ...state, mustMatch: action.keys };

    case "RESET":
  

      return {...action.state, isValid: StandardValidation(action.state)};

    default:
      return state;
  }
};

export const useInputV4 = (initialInputs, initialState) => {
  const [inputState, dispatch] = useReducer(inputReducer, { ...initialInputs, isValid: initialState ? initialState : false });

  const inputHandler = useCallback((id, value, isValid, sid) => {
  

    dispatch({
      type: "INPUT_CHANGE",
      id: id,
      sid: sid,
      value: value,
      isValid: isValid,
    });
  }, []);

  const removeSidHandler = useCallback((sid) => {
    dispatch({
      type: "REMOVE_SID",
      sid: sid,
    });
  }, []);

  const clearSIDHandler = useCallback((sid) => {
    dispatch({
      type: "CLEAR_SID",
      sid: sid,
    });
  }, []);

  const attachmentHandler = useCallback((attachments) => {
    dispatch({
      type: "ATTACHMENT",
      attachments: attachments,
      sid: "attachments",
    });
  }, []);

  const resetState = useCallback((defaultState) => {
    dispatch({
      type: "RESET",
      state: defaultState,
    });
  }, []);

  return { inputState, inputHandler, removeSidHandler, attachmentHandler, resetState };
};
