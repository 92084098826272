import React, { useEffect, useState } from "react";
import HeaderTitle from "../components/headerTitle";
import axios from "axios";
import { useInit } from "../utils/useInit";
import OrganisationDisplay from "../components/organisationDisplay";
import IconSpinner from "../components/iconSpinner";

const Dashboard = () => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const [organisations, setOrganisations] = useState();
  useEffect(() => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/admin/init/organisations`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setOrganisations(response.data.orgData);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err.message ? err.message : err.response.data.message, 0);
      });
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col bg-taskinatorLightGrey">
      <div className="bg-taskinatorDarkGrey h-16 items-center flex p-4">
        <div className="flex items-end ">
          <div className="text-xl">
            <HeaderTitle largeOnly />
          </div>{" "}
          <div className="text-xs text-taskinatorWhite pl-2">Admin</div>
        </div>
      </div>
      <div className=" ">
        {initState[0].started && (
          <div className="flex ">
            <IconSpinner size="m" />
            Loading Data...
          </div>
        )}
        {initState[0].error && <div className="text-taskinatorRed text-center ">Error Loading Data</div>}
        {initState[0].initialised && organisations.length === 0 && <div>No Organisations</div>}
        {initState[0].initialised && (
          <div className="flex flex-wrap">
            {" "}
            {organisations.length > 0 &&
              organisations.map((org) => {
                return <OrganisationDisplay org={org} />;
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
