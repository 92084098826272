import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderTitle = ({ customCSS, largeOnly }) => {
  const navigate = useNavigate();
  return (
    <div className={`${customCSS ? customCSS : "text-taskinatorBlue"} flex cursor-pointer `} onClick={() => navigate("/")}>
      <div className=""> NEXT</div>
      <div className={`lg:block ${largeOnly ? "" : "md:hidden block"}  `}>-</div>
      <div className=" font-bold">TASK</div>
    </div>
  );
};

export default HeaderTitle;
