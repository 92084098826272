import React,{useState} from "react";
import IconSpinner from "./iconSpinner";
import {MdArrowDownward,MdPlayArrow} from "react-icons/md"

const Button = ({ label, onClick, optionsOnClick, displayUp, disabled, customClasses, type, isLoading, error, loadingPercentage }) => {
  const disableButton = disabled || isLoading;
  const [viewOptions, setViewOptions] = useState();

  return (
    <div className="flex flex-col items-center justify-center ">
       
      <div className="flex w-full">
     {optionsOnClick && <button className={`whitespace-nowrap border-r border-r-taskinatorLightGrey  font-semibold flex items-center justify-center  rounded-l-md hover:opacity-80 ${customClasses} ${disabled ? "cursor-not-allowed" : ""}`} onClick={()=>setViewOptions(viewOptions ? null : true)} disabled={disableButton}>
      <div className="px-1 "> {!isLoading && <MdPlayArrow/>}</div> 
      </button>}
      <button className={`whitespace-nowrap  font-semibold px-4 ${optionsOnClick ? "rounded-r-md" : "rounded-md" } hover:opacity-80 ${customClasses} ${disabled ? "cursor-not-allowed" : ""}`} onClick={onClick} type={type} disabled={disableButton}>
        {!isLoading && label}
        {isLoading && (
          <div className="text-taskinatorWhite flex justify-center items-center">
            <IconSpinner color="text-taskinatorWhite" />
            {loadingPercentage && <div className="pl-2 text-xs">{loadingPercentage}</div> }
          </div>
        )}
      </button></div>
      {viewOptions && <div className=" bg-taskinatorWhite shadow border-taskinatorMedGrey rounded z-20 sticky top-2 border w-full" onClick={()=>{setViewOptions()}}>Options </div>}
      {error && <div className="justify-center items-center w-full text-xs text-taskinatorRed text-center">{error}</div>}
    </div>
  );
};
export default Button;
